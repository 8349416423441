module.exports = {
  siteTitle: 'Portfolio', // <title>
  manifestName: 'Portfolio',
  manifestShortName: 'Portfolio', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/vortex.png',
  pathPrefix: `/portfolio/`, // This path is subpath of your hosting https://domain/portfolio
  authorName: 'Rajat Nair',
  headingOne: 'Web & Cloud architect',
  headingTwoPartOne: 'SAFe',
  headingTwoPartTwo: ' Product Owner',
  headingSAFELink: 'https://www.scaledagile.com/',
  headingThree: 'Fullstack developer',
  headingFour: 'Technical Project Manager',
  // social
  socialLinks: [
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/rajatnair',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/rajatnair',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:contact@aunlead.com',
    },
  ],
};
